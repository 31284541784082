<template>
    <v-expansion-panel>
        <template>
            <v-expansion-panel-header class="mt-2">
               Reportes de ventas y compras
                <template v-slot:actions>
                    <v-icon color="primary" @click.native.stop="reload()">
                        mdi-refresh
                </v-icon>
                    <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                        mdi-close
                    </v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1"   v-if="$store.getters['rolesUser/getCustomRole'](232)">
                        Reporte de Cuentas por Cobrar Vencidas
                        <v-icon>mdi-timer-alert</v-icon>
                    </v-tab>
                    <v-tab href="#tab-2"   v-if="$store.getters['rolesUser/getCustomRole'](233)">
                            Resumen  Inventario General 
                            <v-icon>mdi-account-check</v-icon>
                    </v-tab>
                    <v-tab href="#tab-3"   v-if="$store.getters['rolesUser/getCustomRole'](234)">
                        Resumen de Abonos <br />expirados a Vendedores Carsem
                        <v-icon>mdi-account-alert</v-icon>
                    </v-tab>
                    <v-tab href="#tab-4"   v-if="$store.getters['rolesUser/getCustomRole'](235)">
                        Resumen de Movimientos
                        <v-icon>mdi-currency-usd</v-icon>
                    </v-tab>
                    <v-tab href="#tab-5"   v-if="$store.getters['rolesUser/getCustomRole'](236)">
                            Estado de Cuentas
                            <v-icon>mdi-account-check</v-icon>
                    </v-tab>
                    <v-tab href="#tab-6"   v-if="$store.getters['rolesUser/getCustomRole'](237)">
                        Reporte de corte de caja 
                        <v-icon>mdi-currency-usd</v-icon>
                    </v-tab>
                    <!-- <v-tab href="#tab-7"   v-if="$store.getters['rolesUser/getCustomRole'](238)">
                        Resumen Cuentas Por Cobrar 
                        <v-icon>mdi-cash-clock</v-icon>
                    </v-tab> -->
                    <!-- <v-tab href="#tab-2">
                        Reporte de compras
                        <v-icon>mdi-package-variant</v-icon>
                    </v-tab> -->
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item value="tab-1"> 
                        <v-card flat style="padding: 16px;">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <ExpiredAccountsReceivableVue :win="win" :key="key" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat class="pa-5 position-relative">
                            <GeneralInventory :key="key" :business="business"  :datafilter="filterData"  />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                        <v-card flat class="pa-5 position-relative">
                            <SpecialExpiredPayments :key="key" :Sellers="filterData.Sellers"  :business="business"   />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-4">
                        <v-card flat class="pa-5 position-relative">  
                            <v-card-text>
                                <SummaryAccountsSellers  :key="reloadNumber" :win="win" :business="business" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-5">
                        <v-card flat class="pa-5 position-relative">
                            <v-card-text>
                                <SellersAccountsReceivable :key="key" :Customers="filterData.Customers"   />
                            </v-card-text>
                        </v-card>
                    </v-tab-item> 
                    <v-tab-item value="tab-6">
                        <v-card flat class="pa-5 position-relative">
                            <CashierCutCarsem :reportModule="true" :key="reloadNumber" :win="win" :isCarsem="isCarsem"></CashierCutCarsem>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-7">
                        <v-card flat class="pa-5 position-relative">
                            <ResumeAccountsReceivable  :key="key" :business="business"  :datafilter="filterData" />
                        </v-card>
                    </v-tab-item>
                    <!-- <v-tab-item value="tab-2">
                        <v-card flat style="padding: 16px;">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <PurchaseReport :win="win" :key="key" />           
                            </v-card-text>
                        </v-card>
                    </v-tab-item> -->
                </v-tabs-items>
            </v-expansion-panel-content>
        </template>
        
    </v-expansion-panel>
</template>

<script>
    import { mapState } from "vuex";
    import ExpiredAccountsReceivableVue from '../reports/ExpiredAccountsReceivable.vue';
    import SpecialExpiredPayments from '../reports/SpecialExpiredPayments.vue';
    import SummaryAccountsSellers from '../reports/SummaryAccountsSellers.vue';
    import SellersAccountsReceivable from "../reports/SellersAccountsReceivable";
    import CashierCutCarsem from "../../invoices/CashierCutCarsem.vue";
    import GeneralInventory from "../reports/GeneralInventory.vue";
    import ResumeAccountsReceivable from "../reports/ResumeAccountsReceivable.vue";
    import {
        VUE_APP_CARSEM_ID_KEYMASTER1,
        VUE_APP_CARSEM_ID_KEYMASTER2,
        VUE_APP_CARSEM_ID_KEYMASTER3
    } from "@/config";
    export default {
        name: "CarsemReports",
        components: {
            ExpiredAccountsReceivableVue,
            SpecialExpiredPayments,
            SummaryAccountsSellers,
            SellersAccountsReceivable,
            GeneralInventory,
            CashierCutCarsem,
            ResumeAccountsReceivable
        },
        props: ['win'],
        data() {
            return {
            panel: 0,
            tab: "tab-1",
            key: 0,
            isCarsem: false,
            filterData:{
                Warehouses: [],
                Providers: [],
                Families: [],
                Categories: [],
                SubCategories: [],
                batches: [],business: null,
                filterData: {
                    Sellers: [],
                },
                providers: [],
            },
            business: {},
            };
        },
        computed:{
            /*...mapState('customerData', {
                tab: 'tab-1'
            }),*/
            ...mapState("rolesUser", {
                rolesStatus: (state) => state.roles,
            }),
        },
        async mounted(){
            this.verifyCarsem();
            await this.getFilterData();
            this.loadata();

        },
        methods: {
            async loadata() {
                const { businessid = "", } = JSON.parse(localStorage.getItem("user"));
                this.business = await this.$API.business.FindOne(businessid)
                //const Sellers = await this.$API.seller.Find({ID_Business: businessid});
                //this.filterData.Sellers = Sellers.map((seller) => ({name: seller.FirstName + ' ' + seller.LastName  , value: seller.ID_Seller}));
                this.key++;
            },
            reload() {
                this.key++;
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            },    
            getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(";");
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == " ") {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
                },
                verifyCarsem(){
                const keyMastersArray = JSON.parse( this.getCookie( "ID_KeyMaster" ) );
                const specialKeyMasters = keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER1 )  || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER2 ) || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER3 );
                console.log('',specialKeyMasters);
                if(specialKeyMasters){
                    this.isCarsem = true;
                    this.key++;
                }
                else{
                    this.isCarsem = false;
                }
            },
            
            async getFilterData() {
                try {
                    const { businessid = "", branch = "" } = JSON.parse(localStorage.getItem("user"));
                    const filtersWh = { business: businessid, branch };
                

                    const [families, categories, subs, warehouses, batches, providers, invNames, Sellers, Customers, business ] = await Promise.all([
                    this.$API.itemtypes.getFamilies(),
                    this.$API.itemtypes.getCategories(),
                    this.$API.itemtypes.getSubs(),
                    this.$API.warehouse.getWarehouses(filtersWh),
                    this.$API.products.getAllBatch(),
                    this.$API.providers.getProvidersHistory(filtersWh),
                    this.$API.catalogtypedocument.getCatalogTypeDocumentForProvider(),
                    this.$API.sellers.getAllSellers(),
                    this.$API.customers.getCustomerAccountStatus(),
                    this.$API.business.FindOne(businessid)

                    ]);
                
                    console.log('invNames', categories);
                    console.log('invNames', subs);
                    console.log('customers', Customers);
                    this.filterData.Families = families.map((fam) => ({name: fam.name, value: fam.id}));
                    this.filterData.Categories = categories.map((cat) => ({name: cat.name, value: cat.id, parent_id: cat.id_family}));
                    this.filterData.SubCategories = subs.map((sub) => ({name: sub.name, value: sub.id, parent_id: sub.id_cat}));
                    this.filterData.Warehouses = warehouses.map((wh) =>({name: wh.Warehouse, value: wh.ID_Warehouse}));
                    this.filterData.batches = batches.map(({ID_ItemBatch}) => ({name: ID_ItemBatch, value: ID_ItemBatch}));
                    this.filterData.providers = providers.map((prov) => ({name: prov.ProviderName, value: prov.ID_Provider}));
                    this.filterData.invNames = invNames.data.map((inv) => ({name: inv.text, value: inv.value}));
                    this.filterData.Sellers = Sellers.map((seller) => ({name: seller.FirstName + ' ' + seller.LastName  , value: seller.ID_Seller}));
                    this.filterData.Customers = Customers.map((customer) => ({name: customer.LegalName  , value: customer.ID_Customer, address: customer.Address}));
                    this.business = business;
                    this.key++;
                } catch (error) {
                    // Manejar errores aquí
                    console.error("Error al obtener datos:", error);
                }
            }
        },

    }
</script>

<style scoped>
.show .v-list-item {
  min-height: auto !important;
}

.filter-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.justified-column {
  width: 100%;
  border: 1px solid #ccc;
  /* Solo para visualizar el contenedor */
  padding: 10px;
  /* Espaciado interno */
  word-wrap: break-word;
  /* Permite el salto de palabras */
}
</style>